<template>
  <v-btn
    v-bind="$attrs"
    :color="color"
    @click.stop="$emit('click', $event)"
    class="px-4"
    :outlined="outlined"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    outlined: {
      type: Boolean,
      default: false
    }
  }
}
</script>
