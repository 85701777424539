/* eslint-disable no-console */

import { register } from 'register-service-worker'

const { NODE_ENV, BASE_URL } = process.env

// Only register service worker in production mode and if not prerendering
if (NODE_ENV === 'production' && !window.__PRERENDER_INJECTED) {
  register(`${BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered (registration) {
      console.log('Service worker has been registered.')
      // check for updates every 24 hours
      const updateCheckInterval =  1000 * 60 * 60 * 24
      setInterval(() => {
        console.log('Checking for service worker update...')
        // registration.update() triggers the updated() hook
        // if an updated worker is detected
        registration.update()
      }, updateCheckInterval)
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound (registration) {
      console.log('New content is downloading.')
      // A wild service worker has appeared in registration.installing!
      const newWorker = registration.installing
      if (newWorker) {
        newWorker.addEventListener('statechange', () => {
          // newWorker.state has changed
          console.log('New worker state changed to', newWorker.state)
        })
      }
    },
    updated (registration) {
      console.log('New content is available please refresh.')
      document.dispatchEvent(
        new CustomEvent('serviceWorkerUpdated', { detail: registration })
      )
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
