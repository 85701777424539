/**
 * Simple state management for the theme configuration
 */
import localstorage from '@/utils/local-storage'

// Media query for dark color scheme
const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')

// Function that determines if dark theme should be switched on
function themeIsDark (value) {
  // Theme is dark if value is 'dark'
  // or if value is 'system' and system's dark mode is on
  return value === 'dark' || (value === 'system' && darkModeMediaQuery.matches)
}

export const themes = [
  {
    value: 'light',
    name: 'Ljóst',
    icon: 'white-balance-sunny'
  },
  {
    value: 'dark',
    name: 'Dökkt',
    icon: 'weather-night'
  },
  {
    value: 'system',
    name: 'Kerfi',
    icon: 'monitor-cellphone'
  }
]

// Fetch user's last setting
// Default theme is 'light' if no setting was found
const themeValue = localstorage.get('theme') || 'light'

// export dark initial value (imported in @/plugins/vuetify)
export const dark = themeIsDark(themeValue)

// Find the index
const theme = themes.findIndex(t => t.value === themeValue)

// Create store
const theStore = {
  state: {
    theme,
    themeValue,
    dark
  }
}

// Handler for dark color scheme media query
function darkModeOnChange (e) {
  // 'this' should point to the store object
  if (this.state.themeValue === 'system') {
    this.state.dark = e.matches
  }
}

// Listen to system preference.
// Need to bind handler function to the store object.
if ('addEventListener' in darkModeMediaQuery) {
  // MediaQueryList.addEventListener is not supported on Safari <14
  darkModeMediaQuery.addEventListener('change', darkModeOnChange.bind(theStore))
} else {
  darkModeMediaQuery.addListener(darkModeOnChange.bind(theStore))
}

export const store = Object.assign(theStore, {
  updateThemeAction (themeIndex) {
    // Update theme state
    this.state.theme = themeIndex

    const { value } = themes[themeIndex]
    this.state.themeValue = value

    // Save theme in local storage
    localstorage.set('theme', value)

    // Update dark state
    this.state.dark = themeIsDark(value)
  }
})
