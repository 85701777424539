var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('router-view',{attrs:{"name":"appbar"},on:{"toggle-drawer":_vm.toggleDrawer,"toggle-settings-drawer":function($event){_vm.settingsDrawer = !_vm.settingsDrawer}}}),_c('router-view',{on:{"toggle-drawer":_vm.toggleDrawer}}),_c('router-view',{attrs:{"name":"footer","installPromptAvailable":Boolean(_vm.installPrompt)},on:{"install":_vm.install}}),_c('TheNavigationDrawer',{model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}}),_c('TheNavigationDrawerSettings',{model:{value:(_vm.settingsDrawer),callback:function ($$v) {_vm.settingsDrawer=$$v},expression:"settingsDrawer"}}),(!_vm.window__PRERENDER_INJECTED)?_c('v-snackbar',{attrs:{"timeout":-1,"app":"","bottom":"","right":"","vertical":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('VButton',_vm._b({on:{"click":_vm.refreshApp}},'VButton',attrs,false),[_vm._v(" Endurnýja ")])]}}],null,false,2398305148),model:{value:(_vm.updateExists),callback:function ($$v) {_vm.updateExists=$$v},expression:"updateExists"}},[_c('p',[_vm._v("Nýtt efni er tiltækt.")]),_c('p',[_vm._v("Smelltu á "),_c('strong',[_vm._v("Endurnýja")]),_vm._v(" til að uppfæra.")])]):_vm._e(),(_vm.installSnackbar)?_c('v-snackbar',{attrs:{"timeout":-1,"app":"","top":"","right":"","vertical":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('VButton',_vm._b({on:{"click":_vm.closeInstallSnackbar}},'VButton',attrs,false),[_vm._v(" Loka ")])]}}],null,false,3760291627),model:{value:(_vm.installSnackbar),callback:function ($$v) {_vm.installSnackbar=$$v},expression:"installSnackbar"}},[_c('p',{style:({ fontSize: '1.8rem' })},[_vm._v("Sæktu appið fyrir tækið þitt")]),_c('VButton',{style:({
        top: '7px',
        right: '7px'
      }),attrs:{"color":"white","absolute":"","top":"","right":"","icon":""},on:{"click":_vm.closeInstallSnackbar}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1),_c('div',{staticClass:"d-flex flex-column mx-auto mt-6 mb-4"},[(_vm.isWindows10)?[_c('a',{attrs:{"href":"https://microsoft.com/is-is/store/apps/9P4GVBRDLQWV?cid=storebadge&ocid=badge"},on:{"click":_vm.closeInstallSnackbar}},[_c('v-img',{attrs:{"src":require('./assets/img/microsoft-store-badge.png'),"alt":(_vm.$appName + " í Microsoft Store"),"width":250}})],1)]:(_vm.isAndroid)?[_c('a',{attrs:{"href":"https://play.google.com/store/apps/details?id=is.bahai.bokasafn.twa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1&hl=is"},on:{"click":_vm.closeInstallSnackbar}},[_c('v-img',{attrs:{"src":require('./assets/img/play-store-badge.png'),"alt":("Sækja " + _vm.$appName + " frá Google Play"),"width":300}})],1)]:(_vm.installPrompt)?[_c('v-sheet',{staticClass:"d-flex flex-column justify-center align-center pa-5",attrs:{"color":"white","rounded":"xl"}},[_c('LogoAvatar',{staticClass:"mb-3",attrs:{"size":100}}),_c('div',{staticClass:"mb-6",style:({
              color: 'black',
              fontSize: '2.5rem'
            })},[_vm._v(_vm._s(_vm.$appName))]),_c('VButton',{attrs:{"outlined":""},on:{"click":_vm.install}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-widgets")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Sækja")])],1)],1)]:_vm._e()],2),_c('div',{staticClass:"font-weight-light ml-auto mt-4",style:({
        position: 'absolute',
        bottom: '7px',
        left: '15px'
      })},[_c('span',{style:({
          fontSize: '0.85em',
          color: 'white',
          textDecoration: _vm.textDecorationOptOutButton
        }),attrs:{"role":"button"},on:{"click":_vm.optOutInstallSnackbar,"mouseover":function($event){_vm.textDecorationOptOutButton = 'underline'},"mouseout":function($event){_vm.textDecorationOptOutButton = 'none'}}},[_vm._v(" Ekki sýna aftur ")])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }