<template>
  <div>
    <div class="home-hero" style="position: relative;">
      <!--
        Copyright © Bahá’í International Community
        https://www.bahai.org/legal/
        https://media.bahai.org/
      -->
      <v-img
        :src="src"
        :srcset="srcset"
        :sizes="sizes"
        alt="Forsíðumynd"
        height="70vh"
      />
    </div>
    <v-main>
      <v-container class="view-container home">
        <v-row>
          <v-col cols="12" md="6" class="home-intro">
            <h1 class="mb-4">{{ $appName }}</h1>
            <p class="pr-xs-0 pr-sm-5">
              {{ $appName }} er opinber vefheimild bahá’í rita á íslensku.
              Hér má finna þýðingar á ritum Bahá’u’lláh, Bábsins, ‘Abdu’l‑Bahá,
              Shoghi Effendi og Allsherjarhúss réttvísinnar, auk annarra bahá’í rita.
            </p>
          </v-col>
          <v-col cols="12" md="6" class="home-nav">
            <nav>
              <ul class="pl-0">
                <li
                  v-for="s in $pages.tree.sections"
                  :key="s.id"
                  class="home-nav__section mb-7"
                >
                  <p>
                    <router-link :to="s.path" class="text-nowrap">
                      {{ s.title }} »
                    </router-link>
                  </p>
                  <ul>
                    <li
                      v-for="c in s.collections"
                      :key="c.id"
                      class="home-nav__collection"
                    >
                      <p class="my-2">
                        <router-link :to="c.path">{{ c.longTitle }}</router-link>
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {
  name: 'Home',
  comments: true, // show copyright comment
  metaInfo () {
    const link = [
      {
        rel: 'preload',
        as: 'image',
        imagesrcset: `
          /img/hero/hero-sm-810x540.jpg 810w,
          /img/hero/hero-md-1620x1080.jpg 1620w,
          /img/hero/hero-lg-3240x2160.jpg 3240w
        `,
        imagesizes: '100vw'
      }
    ]
    return window.__PRERENDER_INJECTED
      ? {
          link,
          meta: [
            // 48x48 favicon for Google Search Results
            // https://developers.google.com/search/docs/advanced/appearance/favicon-in-search
            {
              rel: 'icon',
              type: 'image/png',
              sizes: '48x48',
              href: '/img/icons/favicon-48x48.png'
            }
          ]
        } : { link }
  },
  data () {
    const prefix = '/img/hero/hero-'
    return {
      src: `${prefix}md-1620x1080.jpg`,
      // Preload hints for responsive hero image in public/index.html
      // Note that preload-webpack-plugin does not yet support
      // imagesrcset and imagesizes for <link rel='preload'>
      // so for now we can't include the images in webpack.
      srcset: [
        'sm-810x540.jpg 810w',
        'md-1620x1080.jpg 1620w',
        'lg-3240x2160.jpg 3240w'
      ].map(s => `${prefix}${s}`).join(','),
      sizes: '100vw'
    }
  }
}
</script>

<style lang="sass">
.home
  .home-intro
    font-size: 1.8rem
    h1
      font-size: 3rem

  .home-nav
    &__section
      font-size: 1.5em
      font-weight: bold
      > p
        @include serif

    &__collection
      font-size: 0.8em
</style>
