if (navigator.userAgent.toLowerCase().indexOf('kaios') > -1) {
  const el = document.createElement('script')
  el.src = 'https://static.kaiads.com/ads-sdk/ads-sdk.v4.min.js'
  el.onload = () => {
    getKaiAd({ /* eslint-disable-line */
      publisher: 'b8582c6c-c952-49bb-9b3f-8ba0f00779cc',
      app: 'bahai-bokasafnid',
      timeout: 5000,
      onerror: err => console.error('Custom catch:', err),
      onready: ad => {
        // Ad is ready to be displayed
        // calling 'display' will display the ad
        ad.call('display')
      }
    })
  }
  document.body.appendChild(el)
}
