import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

import axios from 'axios'

import ViewHome from '@/views/ViewHome.vue'

import localstorage from '@/utils/local-storage'
import { appBarHeight } from '@/utils/constants'

Vue.use(VueRouter)
Vue.use(VueMeta)

const marginals = {
  appbar: () => import(
    /* webpackChunkName "the-app-bar" */
    '@/components/TheAppBar.vue'
  ),
  footer: () => import(
    /* webpackChunkName "the-footer" */
    '@/components/TheFooter.vue'
  )
}

const routes = [
  {
    path: '/t/:id',
    beforeEnter: async ({ path, params }, from, next) => {
      const notFoundLocation = {
        name: 'notFound',
        params: { pathMatch: path.slice(1).split('/') }
      }

      try {
        const { data } = await axios.get(`/api/pages/paragraph/${params.id}`)
        if (data) {
          const { path, hash, error } = data
          if (path) {
            return next({ path, hash })
          } else {
            console.log(error)
          }
        }
      } catch (err) {
        console.log(err)
      }
      next(notFoundLocation)
    }
  },
  {
    path: '/',
    name: 'home',
    components: {
      default: ViewHome,
      ...marginals
    }
  },
  {
    path: '/fyrirvari/:lang?/',
    components: {
      default: () => import(
        /* webpackChunkName: "legal" */ '@/views/ViewLegal.vue'
      ),
      ...marginals
    }
  },
  {
    path: '/hafa-samband/',
    components: {
      default: () => import(
        /* webpackChunkName: "contact" */ '@/views/ViewContact.vue'
      ),
      ...marginals
    }
  },
  {
    path: '/ordabok/',
    components: {
      default: () => import(
        /* webpackChunkName: "dictionary" */ '@/views/ViewDictionary.vue'
      ),
      ...marginals
    }
  },
  {
    path: '/:section',
    name: 'section',
    meta: {
      validate: true
    },
    components: {
      default: () => import(/* webpackChunkName: "section" */ '@/views/ViewSection.vue'),
      ...marginals
    },
    props: { default: true }
  },
  {
    path: '/:section/:collection',
    name: 'collection',
    meta: {
      validate: true
    },
    components: {
      default: () => import(/* webpackChunkName: "collection" */ '@/views/ViewCollection.vue'),
      ...marginals
    },
    props: { default: true }
  },
  {
    path: '/:section/:collection/:collectionItem',
    name: 'collectionItem',
    meta: {
      validate: true
    },
    components: {
      default: () => import(/* webpackChunkName: "collection-item" */ '@/views/ViewCollectionItem.vue'),
      ...marginals
    },
    props: { default: true }
  },
  {
    path: '/:section/:collection/:collectionItem/:work',
    name: 'work',
    meta: {
      validate: true
    },
    components: {
      default: () => import(/* webpackChunkName: "work" */ '@/views/ViewWork.vue')
    },
    props: { default: true }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    components: {
      default: () => import(
        /* webpackChunkName: "not-found" */
        '@/views/ViewNotFound.vue'
      ),
      ...marginals
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.name === 'work') {
      // Delegate scroll behavior to the work view
      return
    }

    if (to.name === 'collectionItem' && to.params.collectionItem === 'skilabod') {
      // If it is a collection item list, delegate scroll behavior to list component
      return
    }

    if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: appBarHeight }
      }

    } else if (savedPosition) {
      return savedPosition

    } else {
      return { x: 0, y: 0 }
    }
  }
})

function routerBeforeEach(routes, to, from, next) {
  const { meta, path } = to

  // Enforce trailing slash for all paths, otherwise we might get a 404
  if (!path.endsWith('/')) {
    return next(path.concat('/'))
  }

  if (meta.validate && !routes.includes(path)) {
    // Route does not exist, display notFound view without changing the url
    return next({
      name: 'notFound',
      // remove leading slash before splitting
      params: { pathMatch: path.replace(/^\//, '').split('/') }
    })
  }

  // Route is valid
  return next()
}

function pluginPages(data) {
  const pages = data

  pages.install = function() {
    Object.defineProperty(Vue.prototype, '$pages', {
      get () { return pages }
    })
  }

  Vue.use(pages)
}

function setRouterNavigationGuard(router, { routes }) {
  router.beforeEach(routerBeforeEach.bind(null, Object.keys(routes)))
}

function attemptFetchLocalStorage(router, resolve, reject) {
  const data = localstorage.getPages()
  if (data) {
    setRouterNavigationGuard(router, data)
    pluginPages(data)
    resolve(router)
  } else {
    reject()
  }
}

export default new Promise((resolve, reject) => {
  if (!navigator.onLine) {
    // If offline we fetch from local storage
    attemptFetchLocalStorage(router, resolve, reject)

  } else {
    // If online we attempt to fetch data from API
    axios.get('/api/pages/routes')
      .then(({ status, data }) => {
        if (!data || (status >= 200 && status < 400)) {
          // Save in browser's local storage
          localstorage.setPages(data)

          setRouterNavigationGuard(router, data)
          pluginPages(data)
          resolve(router)
        } else {
          // Invalid status code or no data returned
          attemptFetchLocalStorage(router, resolve, reject)
        }
      })
      .catch((err) => {
        console.error(err)
        attemptFetchLocalStorage(router, resolve, reject)
      })
  }
})
