function get (key) {
  const data = window.localStorage.getItem(key)
  if (!data) return null
  return JSON.parse(data)
}

function set (key, value) {
  try {
    window.localStorage.setItem(key, JSON.stringify(value))
  } catch (error) {
    console.error(error)
  }
}

const getPages = () => get('pages')
const setPages = (pages) => set('pages', pages)

export default {
  get,
  set,
  getPages,
  setPages
}
