<template>
  <v-avatar
    color="white"
    rounded="circle"
    :size="size"
  >
    <v-img
      :src="require('@/assets/img/logo.png')"
      alt="Merki Bahá’í samfélagsins á Íslandi"
      :max-width="imgMaxWidth"
      contain
    ></v-img>
  </v-avatar>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 40
    }
  },
  computed: {
    imgMaxWidth () {
      return this.size + 3
    }
  }
}
</script>
