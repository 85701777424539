import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'
import minifyTheme from 'minify-css-string'
import { dark } from '@/utils/theme-store'

Vue.use(Vuetify)

const colorLight = '#fafafa' //'rgb(250, 250, 250)'

// https://material.io/design/color/dark-theme.html#properties
const colorDark = '#121212'

const primary = colors.teal.darken4 // '#163569' (#004D40)

const anchorSecondaryLight = '#0571AF'

const options = {
  // https://vuetifyjs.com/en/features/theme/
  theme: {
    options: {
      customProperties: true,
      variations: false,
      minifyTheme
    },
    themes: {
      light: {
        primary,
        anchor: primary,
        anchorSecondary: anchorSecondaryLight,
        anchorSecondaryLight,
        background: colorLight,
        text: colorDark,
        light: colorLight,
        dark: colorDark
      },
      dark: {
        primary,
        anchor: colorLight,
        anchorSecondary: colors.pink.lighten3,
        anchorSecondaryLight,
        background: colorDark,
        text: colorLight,
        light: colorLight,
        dark: colorDark
      }
    },
    dark
  }
}

export default new Vuetify(options)
