<template>
  <v-app>
    <!-- Views -->
    <router-view
      name="appbar"
      @toggle-drawer="toggleDrawer"
      @toggle-settings-drawer="settingsDrawer = !settingsDrawer"
    />
    <!--
      <v-main> needs to be the root element of every
      default view component to avoid strange duplicate
      content bug of prerendered pages. See issue:
      https://github.com/vuejs/vue-router/issues/3237
    -->
    <router-view @toggle-drawer="toggleDrawer" />
    <router-view
      name="footer"
      @install="install"
      :installPromptAvailable="Boolean(installPrompt)"
    />

    <!-- Main navigation drawer -->
    <TheNavigationDrawer v-model="drawer" />
    <TheNavigationDrawerSettings v-model="settingsDrawer" />

    <!-- Snackbars -->
    <v-snackbar
      v-if="!window__PRERENDER_INJECTED"
      v-model="updateExists"
      :timeout="-1"
      app
      bottom
      right
      vertical
    >
      <p>Nýtt efni er tiltækt.</p>
      <p>Smelltu á <strong>Endurnýja</strong> til að uppfæra.</p>
      <template v-slot:action="{ attrs }">
        <VButton
          v-bind="attrs"
          @click="refreshApp"
        >
          Endurnýja
        </VButton>
      </template>
    </v-snackbar>
    <!-- use v-if to prevent visibility in prerendered pages -->
    <v-snackbar
      v-if="installSnackbar"
      v-model="installSnackbar"
      :timeout="-1"
      app
      top
      right
      vertical
    >
      <p :style="{ fontSize: '1.8rem' }">Sæktu appið fyrir tækið þitt</p>
      <VButton
        @click="closeInstallSnackbar"
        color="white"
        absolute
        top
        right
        icon
        :style="{
          top: '7px',
          right: '7px'
        }"
      >
        <v-icon small>mdi-close</v-icon>
      </VButton>
      <div class="d-flex flex-column mx-auto mt-6 mb-4">
        <template v-if="isWindows10">
          <a
            href="https://microsoft.com/is-is/store/apps/9P4GVBRDLQWV?cid=storebadge&ocid=badge"
            @click="closeInstallSnackbar"
          >
            <v-img
              :src="require('./assets/img/microsoft-store-badge.png')"
              :alt="`${$appName} í Microsoft Store`"
              :width="250"
            />
          </a>
        </template>
        <template v-else-if="isAndroid">
          <a
            href="https://play.google.com/store/apps/details?id=is.bahai.bokasafn.twa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1&hl=is"
            @click="closeInstallSnackbar"
          >
            <v-img
              :src="require('./assets/img/play-store-badge.png')"
              :alt="`Sækja ${$appName} frá Google Play`"
              :width="300"
            />
          </a>
        </template>
        <template v-else-if="installPrompt">
          <v-sheet
            color="white"
            rounded="xl"
            class="d-flex flex-column justify-center align-center pa-5"
          >
            <LogoAvatar :size="100" class="mb-3" />
            <div
              :style="{
                color: 'black',
                fontSize: '2.5rem'
              }"
              class="mb-6"
            >{{ $appName }}</div>
            <VButton
              outlined
              @click="install"
            >
              <v-icon left>mdi-widgets</v-icon>
              <span class="font-weight-bold">Sækja</span>
            </VButton>
          </v-sheet>
        </template>
      </div>
      <div
        class="font-weight-light ml-auto mt-4"
        :style="{
          position: 'absolute',
          bottom: '7px',
          left: '15px'
        }"
      >
        <span
          role="button"
          @click="optOutInstallSnackbar"
          @mouseover="textDecorationOptOutButton = 'underline'"
          @mouseout="textDecorationOptOutButton = 'none'"
          :style="{
            fontSize: '0.85em',
            color: 'white',
            textDecoration: textDecorationOptOutButton
          }"
        >
          Ekki sýna aftur
        </span>
      </div>
      <template v-slot:action="{ attrs }">
        <VButton
          v-bind="attrs"
          @click="closeInstallSnackbar"
        >
          Loka
        </VButton>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import LogoAvatar from '@/components/LogoAvatar.vue'
import VButton from '@/components/VButton.vue'

import localstorage from '@/utils/local-storage'

export default {
  name: 'App',
  components: {
    TheNavigationDrawer: () => import(
      /* webpackChunkName: "the-navigation-drawer" */
      '@/components/TheNavigationDrawer.vue'
    ),
    TheNavigationDrawerSettings: () => import(
      /* webpackChunkName: "the-navigation-drawer-settings" */
      '@/components/TheNavigationDrawerSettings.vue'
    ),
    LogoAvatar,
    VButton
  },
  metaInfo() {
    const {
      $pages: { routes },
      $route: { path }
    } = this


    const defaultTitle = this.$appName
    const defaultDescription = routes['/'].description

    const {
      title: routeTitle,
      description = defaultDescription
    } = routes[path]

    const title = routeTitle || defaultTitle
    const titleTemplate = path === '/' || !routeTitle
      ? undefined
      : `%s | ${defaultTitle}`

    // Define info object
    const info = {
      title,
      titleTemplate
    }

    if (window.__PRERENDER_INJECTED) {
      const {
        VUE_APP_BASE_URL: baseUrl,
        VUE_APP_FB_APPID: fbAppId = ''
      } = process.env

      if (!baseUrl) {
        throw new Error('Env variable VUE_APP_BASE_URL undefined')
      }

      // Note: the open-graph tags defined here are scraped from the prerendered page
      // See why they can't be scraped from an SPA https://github.com/nuxt/vue-meta/issues/133
      info.meta = [
        {
          name: 'description',
          content: description
        },
        {
          property: 'fb:app_id',
          content: fbAppId
        },
        {
          property: 'og:site_name',
          content: 'Bahá’í samfélagið'
        },
        {
          property: 'og:locale',
          content: 'is_IS'
        },
        {
          property: 'og:title',
          content: title,
          template: titleTemplate,
          vmid: 'og:title'
        },
        {
          property: 'og:description',
          content: description,
          vmid: 'og:description'
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:url',
          content: `${baseUrl}${path}`
        },
        {
          property: 'og:image',
          content: `${baseUrl}/img/open-graph/og-image.png`
        },
        {
          property: 'og:image:height',
          content: '630'
        },
        {
          property: 'og:image:width',
          content: '1200'
        },
        {
          property: 'og:image:alt',
          content: 'Merki Bahá’í samfélagsins á Íslandi'
        }
      ]
    }

    return info
  },
  data () {
    return {
      drawer: false,
      settingsDrawer: false,
      refreshing: false,
      registration: null,
      updateExists: false,
      installSnackbarOptedOut: false,
      installSnackbarOptOutKey: 'installSnackbarOptOut',
      installPrompt: null,
      isWindows10: /Windows NT 10/i.test(navigator.userAgent),
      isAndroid: /Android/i.test(navigator.userAgent),
      installedApps: [],
      installedAppsLoaded: false,
      snackbarTimeout: false,
      textDecorationOptOutButton: 'none',
      window__PRERENDER_INJECTED: window.__PRERENDER_INJECTED
    }
  },
  computed: {
    installSnackbar () {
      const standaloneMode = window.matchMedia('(display-mode: standalone)').matches
      const {
        installSnackbarOptedOut,
        installedApps,
        installedAppsLoaded,
        installPrompt,
        isAndroid,
        isWindows10,
        snackbarTimeout
      } = this
      const snackbarVisible = (
        // Do not show snackbar if any of the following conditions is met:
        // 1. We are in development mode or prerendering
        // 2. User is in standalone display mode (means he is viewing installed app)
        // 3. User has opted out from the snackbar
        // 4. navigator.getInstalledRelatedApps() is a non-empty Array
        // 5. Install prompt is not available and user is not on Android or Windows 10
        process.env.NODE_ENV === 'production'
        && !window.__PRERENDER_INJECTED
        && !standaloneMode
        && !installSnackbarOptedOut
        && (!('getInstalledRelatedApps' in navigator) || installedAppsLoaded)
        && installedApps.length === 0
        && (installPrompt || isAndroid || isWindows10)
        && snackbarTimeout
      )

      return snackbarVisible
    }
  },
  created () {
    if (!window.__PRERENDER_INJECTED) {
      // Listen to 'serviceWorkerUpdated' custom event
      // It is fired in registerServiceWorker.js updated() hook
      // when a new service worker is available
      document.addEventListener(
        'serviceWorkerUpdated', this.showRefreshUI, { once: true }
      )

      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener(
          'controllerchange', () => {
            // This fires when the service worker controlling this page
            // changes, e.g. a new worker has skipped waiting and become
            // the new active worker. Fired when this.refreshApp() is called.
            if (this.refreshing) return
            this.refreshing = true
            window.location.reload()
          }
        )
      }

      const optOut = localstorage.get(this.installSnackbarOptOutKey)
      this.installSnackbarOptedOut = optOut

      if ('onbeforeinstallprompt' in window) {
        window.addEventListener('beforeinstallprompt', evt => {
          // Prevent earlier versions of Chrome from automatically showing the prompt
          evt.preventDefault()
          this.installPrompt = evt
        })
      }

      if ('getInstalledRelatedApps' in navigator) {
        // returns Array of Objects { platform, url, id, version }
        navigator.getInstalledRelatedApps().then(apps => {
          this.installedApps = apps
          this.installedAppsLoaded = true
        })
      }

      // Delay rendering the snackbar
      window.setTimeout(() => (this.snackbarTimeout = true), 20000)
    }
  },
  methods: {
    toggleDrawer () {
      this.drawer = !this.drawer
    },
    showRefreshUI (event) {
      this.registration = event.detail
      this.updateExists = true
    },
    refreshApp () {
      this.updateExists = false
      if (this.registration && this.registration.waiting) {
        // Send a message to the 'waiting' service worker
        // to skip waiting and go ahead install itself
        this.registration.waiting.postMessage('skipWaiting')
      }
    },
    install () {
      if (this.installPrompt) {
        this.installPrompt.prompt()
        // Note: installPrompt object also has `platforms` field
        // which we might want to exploit in the future
        this.installPrompt.userChoice.then(({ outcome }) => {
          if (outcome === 'accepted') {
            // User has installed the PWA on the device
            this.installPrompt = null
            // Never show the install prompt again
            this.optOutInstallSnackbar()
          }
        })
      }
    },
    closeInstallSnackbar () {
      this.installSnackbarOptedOut = true
    },
    optOutInstallSnackbar () {
      localstorage.set(this.installSnackbarOptOutKey, true)
      this.closeInstallSnackbar()
    }
  }
}
</script>

<style lang="sass">
@import "./assets/styles/"
</style>
