import Vue from 'vue'

Vue.prototype.$appName = 'Bahá’í vefbókasafnið'

import App from './App.vue'
import './registerServiceWorker'
import './kaiads'

import vuetify from './plugins/vuetify'
import routerPromise from './router'

Vue.config.productionTip = false

routerPromise.then(router => {
  if (router) {
    new Vue({
      router,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }
}).catch(console.error)
